import global from "redux/reducer";
import dashboard from "ui/pages/Dashboard/redux/reducer";
import communications from "ui/pages/communications/redux/reducer";
import marketing from "ui/pages/marketing/redux/reducer";
import webpage_detail from "ui/pages/TemplatePreview/redux/reducer";
import transactions from "ui/pages/transactions/redux/reducers";
import schedule from "ui/pages/schedule/redux/reducer";
import partnership from "ui/pages/Partnerships/redux/reducer";
import exlyFeedback from "ui/pages/ExlyFeedbacks/redux/reducer";
import whatsappTemplates from "ui/pages/whatsappBroadcast/redux/reducer";
import salesPages from "ui/pages/SalesPages/Redux/reducer";
import community from "ui/pages/ManageBrandedCommunity/redux/reducer";
import myAccount from "features/Account/redux/Account.reducer";
import exlySegment from "ui/pages/customers/Segments/redux/reducer";
import thankyou from "ui/pages/ThankYou/redux/reducer";
import crm from "features/Crm/modules/CrmResponses/utils/redux/Crm.reducers";
import mediaLibrary from "features/MediaLibrary/redux/MediaLibrary.reducer";
import affiliateMarketing from "features/AffiliateMarketing/redux/Affliates.reducer";
import checkoutPagesReducer from "features/CheckoutPages/utils/CheckoutPagesReducer.utils";
import onboarding from "features/Onboarding/redux/Onboarding.reducer";
import uiLayout from "features/UILayout/redux/UILayout.reducer";

export default {
  global,
  dashboard,
  communications,
  marketing,
  mediaLibrary,
  webpage_detail,
  transactions,
  schedule,
  partnership,
  exlyFeedback,
  whatsappTemplates,
  salesPages,
  community,
  exlySegment,
  myAccount,
  thankyou,
  crm,
  affiliateMarketing,
  checkoutPagesReducer,
  onboarding,
  uiLayout,
};
